import React,{useState} from 'react';
import { IoMdArrowBack } from "react-icons/io";
import { IoMdArrowForward } from "react-icons/io";
import jbk from '../../../../assets/jbk2.png';
import report2 from '../../../../assets/reportIcon2.svg';
import './Trending.scss';


const Trending = () => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        if (currentIndex < Math.ceil(jump.length / 4) - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

const jump =[
    {
        para:"Sun meets sea: Floating PV to play a key role in Southeast Asia’s solar.",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply Chain and Opportunities in the Energy ",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply Chain and Opportunities in the",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply Chain and Opportunities ",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply Chain and ",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and Gas Supply",
        img1:jbk,
        img2:report2
    },
    {
        para:"African Oil and ",
        img1:jbk,
        img2:report2
    },
    {
        para:"Anigeriam erigbnoekn nreoneognog O",
        img1:jbk,
        img2:report2
    },
    {
        para:"Report finds more than 200 projects could benefit from  Opportunities in ",
        img1:jbk,
        img2:report2
    },
    {
        para:"Report finds more than 200 projects could benefit from subsea boosting",
        img1:jbk,
        img2:report2
    },
    {
        para:"Report finds more than 200 projects could benefit",
        img1:jbk,
        img2:report2
    }
]
  return (
    <div className='trending-main'>

   
    <div className='trending-cont'>

        <div className='trending-header'>
            <h4>Trending</h4>
            <div className='trending-arrow'>
            <IoMdArrowBack 
            color={currentIndex === 0 ? "#A29999" : "#000000"}
            size={20} onClick={handlePrev} />

            <IoMdArrowForward 
             color={currentIndex === Math.ceil(jump.length /4) - 1 ? "#A29999" : "#000000"}
            size={20} 
            onClick={handleNext} 
            />
            
            </div>
        </div>
     
        <div className='trending-two'>
        {jump.map((item,index)=>(

       
            <div 
            className={`trending-two-box ${index < currentIndex * 4 || index >= (currentIndex + 1) * 4 ? 'hidden' : ''}`} key={index}
            >
                <div className='trending-two-img'>
                    <img src={item.img1} alt="" />
                </div>

                <div className='trending-two-type'>
                        <img src={report2} alt="imgI" />
                        <p>REPORT</p>
                </div>
                
                <div className='trending-two-content'>
                    <p>{item.para}</p>
                </div>

                <div className='trending-updated'>
                     <p>Updated: 22/09/2024</p>
                </div>
            </div>
        ))}
        </div>

    </div>
    </div>
  )
}

export default Trending
