import React from 'react';
import './Banner.scss'

const Banner = () => {
  return (
    <div className='banner-cont'>
      <p>This is a clickable CTA banner to announce new features, updates, etc.</p>
    </div>
  )
}

export default Banner
