import React from 'react'
import ConsumerSidebar from '../Reusable/ConsumerSidebar/ConsumerSidebar'
import Navbar from '../Reusable/Navbar/Navbar'

const ConsumerReport = () => {
  return (
    <div className='dashboard-main'>
      <ConsumerSidebar/>
      <section>
        <Navbar/>
      </section>
    </div>
  )
}

export default ConsumerReport
