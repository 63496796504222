import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

export default function Spinner({color,size}) {
    return (
        <div>
            <CircularProgress  size={size} style={{ color: color}} />
        </div>
    )
}
