import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";

import './SeeAllBox.scss';
import report2 from '../../../../assets/map-green.svg';
import empty from '../../../../assets/Empty State.png'
const SeeAllBoxTwo = ({
    headerTitle,
    imageHeight,
    imageWidth,
    sliceNo,
    contHeight,
    contWidth,
    paddingMain,
    emptyhd,
    jump,
}) => {

    
   

const slicedItems = jump.slice(0, sliceNo);

const useStyle1={
    width: imageWidth,
    height: imageHeight
}
const useStyle2={
    height: contHeight,
    width:contWidth
}
const useStyle3={ 
    width:imageWidth
}
const useStyle4={ 
    padding:paddingMain
}

  return (
    <div style={useStyle4} className='see-all-box-main'>
      <div style={useStyle2} className='see-all-box-cont'>

            <div className='see-all-box-header'>
                <h4>{headerTitle}</h4>
                <div className='see-all-box-arrow'>
                <p>See all</p>  
                <IoIosArrowRoundForward
                    size={24}
                    />
                </div>
            </div>


            <div className='see-all-box-two'>
                {slicedItems.length ===0 ? (
                    <div className='empty-state'>
                        <div>
                            <div>
                            <img src={empty} alt="icon" />
                            </div>
                      
                            <p>No {emptyhd} available</p>
                        </div>
                        
                    </div>
                    ):(
                    <>
                    {slicedItems.map((item,index)=>(

                        <div key={index} style={useStyle3} className='see-all-box-two-box'>
    
                            <div  style={useStyle1} className='see-all-box-two-img'>
                                <img src={item.img1} alt="" />
                            </div>
    
                            <div className='trending-two-type'>
                            <img src={report2} alt="imgI" />
                                <p>VISUALIZATION</p>
                            </div>
    
                            <div className='see-all-box-two-title'>
                                <p>{item.para}</p>
                            </div>
    
                        
                            <div className='see-all-box-updated'>
                                <p>Updated: 22/09/2024</p>
                            </div>
                        </div>
                    ))}
                    </>
                )}
                
            </div>
      </div>
    </div>
  )
}

export default SeeAllBoxTwo
