import React, { useState } from 'react';
import CalendarCSS from './calendar.module.css';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

const Calendar = ({ onDateSelect,top }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [currMonth, setCurrMonth] = useState(new Date().getMonth());
    const [currYear, setCurrYear] = useState(new Date().getFullYear());

    const todayDate = new Date(); // Get current date in local time zone
    todayDate.setHours(0, 0, 0, 0); // Ensure time is not affecting date comparisons

    const renderCalendar = () => {
        const firstDayOfMonth = new Date(currYear, currMonth, 1).getDay(); // Get the weekday of the 1st of the month
        const lastDateOfMonth = new Date(currYear, currMonth + 1, 0).getDate(); // Last day of the current month
        const lastDateOfPrevMonth = new Date(currYear, currMonth, 0).getDate(); // Last day of the previous month
    
        const liTags = [];
    
        // Fill in the days from the previous month
        for (let i = firstDayOfMonth; i > 0; i--) {
            
            liTags.push(
                <li
                    key={`prev-month-${i}`}
                    className={`${CalendarCSS.notCurrent}`} // Inactive class for previous month
                >
                    {lastDateOfPrevMonth - i + 1}
                </li>
            );
        }
    
        // Render the days of the current month
        for (let i = 1; i <= lastDateOfMonth; i++) {
            const date = new Date(currYear, currMonth, i);
            const isToday = date.getDate() === todayDate.getDate() && date.getMonth() === todayDate.getMonth() && date.getFullYear() === todayDate.getFullYear();
            const isSelected = selectedDate && date.toDateString() === selectedDate.toDateString();
            const isFutureDate = date > todayDate; // Check if date is in the future
    
            liTags.push(
                <li
                    key={`day-${i}`}
                    className={`${isToday ? CalendarCSS.today : ''} ${isSelected ? CalendarCSS.active : ''} ${isFutureDate ? CalendarCSS.inactive : ''}`}
                    onClick={() => handleDateClick(date)}
                >
                    {i}
                </li>
            );
        }
    
        // Optionally, fill in the remaining days of the next month to complete the grid
        const totalSlots = 35; // 6 rows * 7 days
        const remainingDays = totalSlots - liTags.length;
        for (let i = 1; i <= remainingDays; i++) {
            liTags.push(
                <li
                    key={`next-month-${i}`}
                    className={`${CalendarCSS.notCurrent}`} // Inactive class for next month
                >
                    {i}
                </li>
            );
        }
    
        return liTags;
    }
    const handleMonthChange = (change) => {
        let newMonth = currMonth + change;
        let newYear = currYear;
        if (newMonth < 0) {
            newYear--;
            newMonth = 11;
        } else if (newMonth > 11) {
            newYear++;
            newMonth = 0;
        }

        // If the selected month is the future, prevent changing to it
        const newDate = new Date(newYear, newMonth);
        if (newDate > todayDate) {
            return; // Prevent going to future months
        }

        setCurrMonth(newMonth);
        setCurrYear(newYear);
    };

    const handleDateClick = (date) => {
        if (date > todayDate) {
            return; // Prevent selecting future dates
        }
        setSelectedDate(date);
        if (onDateSelect) {
            onDateSelect(date);
            // onClose();
        }
    };

    const useStyle={
        top:top
    }

    return (
        <div style={useStyle} className={CalendarCSS.wrapper}>
            <header>
                <p className={CalendarCSS.currentDate}>{`${new Date(currYear, currMonth).toLocaleString('default', { month: 'long' })} ${currYear}`}</p>
                <div className={CalendarCSS.icons}>
                    <span id="prev" onClick={() => handleMonthChange(-1)}><FaChevronLeft className={CalendarCSS.arrowbtn} /></span>
                    <span id="next" onClick={() => handleMonthChange(1)}><FaChevronRight className={CalendarCSS.arrowbtn} /></span>
                </div>
            </header>
            <div className={CalendarCSS.calendar}>
                <ul className={CalendarCSS.weeks}>
                    <li>M</li>
                    <li>T</li>
                    <li>W</li>
                    <li>T</li>
                    <li>F</li>
                    <li>S</li>
                    <li>S</li>
                </ul>
                <ul className={CalendarCSS.days}>
                    {renderCalendar()}
                </ul>
            </div>
        </div>
    );
};

export default Calendar;
