import React, { useState, useRef,useEffect } from 'react';
import back from '../../../assets/backicon.svg';
import './EmailVerification.scss';
import OnboardingSidebar from '../Reusables/OnboardingSidebar/OnboardingSidebar';
import TermsandPrivacy from '../Reusables/TermsandPrivacy/TermsandPrivacy';
import { Link,useNavigate } from 'react-router-dom';
import BaseButton from '../Reusables/BaseButton/BaseButton';
import axios from "axios";
import { useSelector } from "react-redux";
import Spinner from '../Reusables/spinner/spinner';
import { useDispatch } from "react-redux";
import { USER_LOGIN_SUCCESS } from '../../redux/userConstants';
const EmailVerification = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = useRef([]);
    const [loading,setLoading] = useState(false);
    const [loadingResend,setLoadingResend] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(null);
    const [countdown, setCountdown] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleChange = (element, index) => {
      if (isNaN(element.value)) return;
  
      const newOtp = [...otp];
      newOtp[index] = element.value;
  
      setOtp(newOtp);
  
      // Focus on the next input field
      if (element.value !== '' && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    };
  
    const handleBackspace = (event, index) => {
      if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
        inputRefs.current[index - 1].focus();
      }
    };
  
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData.getData('Text');
      if (clipboardData.length === 6 && /^\d+$/.test(clipboardData)) {
        setOtp(clipboardData.split(''));
        inputRefs.current[5].focus();
      }
    };

    const handleConfirmOtp = () => {
      setLoading(true)
     
          let body={  
          
              "otp": otp.join('')
          }
        
      
          
        try {
          setLoading(true)
          const config = {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`
            },
          };
    
          axios
            .post(`${baseUrl}auth/verify`,body,config)
            .then((res) => {
              console.log(res)
                // alert(res.data.data.message)
                const { user, token } = res.data.data;
                dispatch({
                  type: USER_LOGIN_SUCCESS,
                  payload: { user, token },  
                });   
                localStorage.setItem('userInfo', JSON.stringify({ user, token }));
              
                navigate('/getstarted/flow-1/')
                setLoading(false)
            
            })
            .catch((error) => {
              
                setLoading(false)
             
              if (error.response) {
                alert(error.response.data.message);
              } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
              } else {
                alert("An error occurred. Please try again later.");
              }
            });
        }
        catch (error) {
          console.log("error", error);
        }
         
    };

    const handleResendOtp = () => {
          
        try {
          setLoadingResend(true)
          const config = {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`
            },
          };
    
            axios.get(`${baseUrl}auth/verify`, config)
            .then((res) => {
              console.log(res)
              setLoadingResend(false)
              alert(res.data.message);
              setIsDisabled(true);
              setCountdown(300); 

             
              setTimer(setInterval(() => {
                setCountdown(prev => {
                  if (prev <= 1) {
                    clearInterval(timer);
                    setIsDisabled(false);
                    return 0;
                  }
                  return prev - 1;
                });
              }, 1000));
            })
            .catch((error) => {
              console.log(error)
              setLoadingResend(false)
              if (error.response) {
                alert(error.response.data.message);
              } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
              } else {
                alert("An error occurred. Please try again later.");
              }
            });
        }
        catch (error) {
          console.log("error", error);
        }
         
    };

    // const handleClick = () => {
      
    // };

    useEffect(() => {
  
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }, [timer]);

  return (
    <div className='onboard_cont'>
        <div>
        <OnboardingSidebar/>
        </div>
       
        <div className='verify_mail_content'>

            <div >
                <Link to="/signup">
                    <img src={back} alt="icon" style={{ cursor: 'pointer' }} />
                </Link>
            </div>

            <h3>
                Verify Your Email
            </h3>

            <p>Enter the 6-digit OTP sent to your email.</p>

            <div onPaste={handlePaste}>
                {otp.map((_, index) => (
                    <input
                        key={index}
                        type="text"
                        className="otp-input"
                        maxLength="1"
                        value={otp[index]}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleBackspace(e, index)}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        style={{
                            width: '48px',
                            height: '48px',
                            textAlign: 'center',
                            fontSize: '18px',
                            marginRight: '8px'
                        }}
                    />
                 ))}
            </div>

            <div>
              {isDisabled && (
                <p className='otp_cntdw'>Resend OTP in {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}</p>
              )}
            </div>
           

            <div className='verify_btns'>
            {loadingResend ?(
                  <div style={{textAlign:'center'}}>

               
                  <Spinner
                   color={"#007EFF"}
                  />
                     </div>
              
             ):(
                <button
                type='submit'
                className={isDisabled?'resend_otp_btn alt':'resend_otp_btn'}
                onClick={()=>handleResendOtp()}
                disabled={isDisabled?true:false}
                >
                    Resend OTP
                </button>
             )}
                {loading ?(
                  
                     <Spinner
                      color={"#007EFF"}
                     />
                 
                ):(
                  <BaseButton
                      disabled={otp.some(value => value === '')} 
                      title={"Confirm OTP"}
                      width={"140px"}
                      height={"44px"}
                      padding={"0.5em 1em"}
                      onClick={()=>handleConfirmOtp()}
                  />)}
                
            </div>


            <TermsandPrivacy/>

        </div>
       
    </div>
  )
}

export default EmailVerification
