import React,{useState,useEffect} from "react";
import './Navbar.scss';
import { IoSearchOutline } from "react-icons/io5";
import bell from '../../../../assets/belIIcon.svg';
import set from '../../../../assets/navicon.svg';
import { IoIosArrowRoundForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const Navbar = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [role, setRole] = useState(null);
    const userData = useSelector((state) => state.userLogin.userInfo.user);
    const accountType = userData.account_type;
    const location = useLocation();
    const isConsumer = location.pathname.startsWith("/consumer");
    const isContributor = location.pathname.startsWith("/contributor");
    

    useEffect(() => {
    
        if (userInfo) {
          if (accountType === "consumer_contributor" ) {
            setRole('hybrid');
         
          } 
           else {
            setRole(null);
          }
        } else {
          setRole(null);
        }
 // eslint-disable-next-line
      }, [userInfo]);

  return (
    <nav className='nav-cont'>
        <div className='nav-search-box'>
            
            <input 
            type="text" 
            placeholder='Search here...'
            />
            <IoSearchOutline
            
            size={20}/> 
        </div>

        {role === 'hybrid' && (
        <div className='switch-account'>
            {isConsumer && <a href='/contributor/dashboard'>Switch to Contributor</a>}
            {isContributor &&  <a href='/consumer/dashboard'>Switch to Consumer</a>}
           
            
        </div>)}

        <div className='nav-notif'>
            <div>
                <img src={bell} alt="icon" />
            </div>
            <div className='nav-notif-set'>
                <div>
                <img src={set} alt="icon" />
                </div>
                
                <p>Set Preferences</p>
                <IoIosArrowRoundForward
                color='#9CA0A5'
                size={20}
                />
            </div>
        </div>
    </nav>
  )
}

export default Navbar
