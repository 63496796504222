import React from 'react';
import icon from '../../../../assets/newsIcon2.svg';
import { IoIosArrowRoundForward } from "react-icons/io";
import './NewsSeeAll.scss'

const NewsSeeAll = ({sliceNo,contHeight}) => {

    const jump =[
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy StrugglesOil Production in OML 13",
            para :"BP announced the discovery of a new natural...",
            
        },
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy Struggles",
            para :"BP announced the discovery of a new natural...",
            
        },
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy Struggles",
            para :"BP announced the discovery of a new natural...",
           
            
        },
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy Struggles",
            
            para :"BP announced the discovery of a new natural...",
        },
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy Struggles ",
            para :"BP announced the discovery of a new natural...",
            
        },
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy Struggles",
            para :"BP announced the discovery of a new natural...",
            
        },
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy Struggles ",
            para :"BP announced the discovery of a new natural...",
            
        },
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy Struggles",
            
            
        },
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy Struggles ",
            
            
        },
        {
            header:"BP Discovers New Natural Gas Reserve Amid Egypt’s Energy Struggles",
            
            
        },
        {
            header:"Report finds more than 200 projects could benefit",
            
            
        }
    ]

    const slicedItems = jump.slice(0, sliceNo);

    const useStyle1={
       
        height: contHeight
    }
  return (
    <div className='news-see-all-main'>
      <div style={useStyle1} className='news-see-all-cont'>
            <div className='news-see-all-header'>
                <h4>News</h4>
                <div className='news-see-all-arrow'>
                <p>See all</p>  
                <IoIosArrowRoundForward
                    size={24}
                    />
                </div>
            </div>

            {slicedItems.map((item,index)=>(
                <div key={index} className='news-see-all-content'>
                    <div className='news-see-all-img'> 
                        <img src={icon} alt="" />
                    </div>
                    <div className='news-see-all-title'>
                        <h4>{item.header}</h4>
                        <p>{item.para}</p>
                        <div className='news-see-all-source'>
                            <p>SOLAR</p>
                            <h5>CNN</h5>
                        </div>
                    </div>
                </div>
            ))}
            
      </div>
    </div>
  )
}

export default NewsSeeAll
