import React from 'react';
import './ReportCard.scss';
import logo from '../../../../../src/assets/reportcardlogo.png'
const ReportCard = () => {
  return (
    
    <div className="report-crd-cont">
        <div className="report-crd-cont-img">
            <img src={logo} alt="logo" />
        </div>
        <p className="report-crd-text">African Oil and Gas Supply Chain and Opportunities in the Energy Transistion</p>
        <div className="report-crd-cont-line">

        </div>

       <p className="report-crd-date">September 2024</p>
    </div>
    
  )
}

export default ReportCard
