import React from 'react'
import ConsumerSidebar from '../Reusable/ConsumerSidebar/ConsumerSidebar'
import Navbar from '../Reusable/Navbar/Navbar'

const ConsumerProfile = () => {
  return (
    <div className='dashboard-main'>
      
      <div>
      <ConsumerSidebar/>
    </div>
      
      <section>
        <Navbar/>
      </section>
    </div>
  )
}

export default ConsumerProfile
