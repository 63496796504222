import React from 'react';
import jbk from '../../../../assets/jbk2.png';
import { IoMdArrowForward } from "react-icons/io";
import './GettingStarted.scss'
const GettingStarted = () => {
   

const jump =[
    {
        para:"BP announced the discovery of a new natural....",
        img1:jbk,
        header:"How to Publish a Journal"
    },
    {
        para:"BP announced the discovery of a new natural... ",
        img1:jbk,
        header:"How to add to Visualization"
    },
    {
        para:"BP announced the discovery of a new natural...",
        img1:jbk,
         header:"How to add to Datasets"
    },
    {
        para:"BP announced the discovery of a new natural...",
        img1:jbk,
         header:"How to add to Datasets"
    },
   
    
]
  return (
    <div className='get-started-main'>

   
    <div className='get-started-cont'>

        <div className='get-started-header'>
            <h4>Getting Started</h4>
        </div>
     
        <div className='get-started-two'>
        {jump.map((item,index)=>(

       
            <div 
            className= "get-started-two-box" key={index}
            >
                <div className='get-started-two-img'>
                    <img src={item.img1} alt="" />
                </div>
                
                <div className='get-started-two-header'>
                    <p>{item.header}</p>
                </div>
                <div className='get-started-two-content'>
                    <p>{item.para}</p>
                </div>

                <div className='get-started-learn'>

                     <p>Learn More</p>
                     
                    <IoMdArrowForward 
                    color="#007EFF"
                    size={20} 
                    // onClick={handleNext} 
                    />
                </div>
            </div>
        ))}
        </div>

    </div>
    </div>
  )
}


export default GettingStarted
