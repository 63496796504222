import React from 'react';
import './Pagination.scss';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Pagination = ({ totalRecords, recordsPerPage,currentPage, onPageChange, pageRange }) => {
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    // const [currentPage, setCurrentPage] = useState(1);

    // Calculate the pages to show in the pagination
    const getPageNumbers = () => {
        const startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
        const endPage = Math.min(totalPages, startPage + pageRange - 1);
        
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    // Handle page change
    const handleClick = (page) => {
        // setCurrentPage(page);
        onPageChange(page);
    };

    // Calculate the record range text
    const startRecord = (currentPage - 1) * recordsPerPage + 1;
    const endRecord = Math.min(currentPage * recordsPerPage, totalRecords);

    return (
        <div className="pagination-container">
            <div className="record-range">
                <p>Showing: {startRecord}-<span> {endRecord} of {totalRecords}</span> </p>
                
            </div>
            <div className="pagination">
          
                    <IoIosArrowBack 
                     onClick={() => currentPage > 1 && handleClick(currentPage - 1)}
                     color={currentPage === 1 ? "#DDD8D0" : "#77787C"}
                    />
                
                {getPageNumbers().map((page) => (
                    <button 
                        key={page} 
                        onClick={() => handleClick(page)}
                        className={page === currentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                    <IoIosArrowForward
                        
                        onClick={() => currentPage < totalPages && handleClick(currentPage + 1)}
                        className={currentPage === totalPages ? "#DDD8D0" : "#77787C"}
                    />
               
            </div>
        </div>
    );
};

export default Pagination;
