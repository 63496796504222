import React from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";
import './MyActivity.scss';
import img1 from '../../../../assets/contributionsIcon.png';
import img2 from '../../../../assets/mergeRateIcon.png';
import img3 from '../../../../assets/earning.png';
import img4 from '../../../../assets/wihtdrawalIcon.png';

const MyActivity = () => {
  return (
    <div className='my-actvy-main'>

    
    <div className='my-actvy-cont'>

      <div className='my-actvy-header'>
        <h4>My Activity</h4>
        <div className='actvy-see-all-btn'>  
                <p>See all activity</p>
                <IoIosArrowRoundForward
                color='#000000'
                size={20}
                />
        </div>
      </div>

      <div className='my-actvy-grid'>
                <div className='my-actvy-content'>
                    <div>
                        <img src={img1} alt="" />
                    </div>
                    <div>
                        <h4>24</h4>
                        <p>Contributions</p>
                    </div>
                </div>
                
                <div className='my-actvy-content'>
                    <div>
                        <img src={img2} alt="" />
                    </div>
                    <div>
                        <h4>80%</h4>
                        <p>Merge Rate</p>
                    </div>
                </div>

                <div className='my-actvy-content'>
                    <div>
                        <img src={img3} alt="" />
                    </div>
                    <div>
                        <h4>$12,000</h4>
                        <p>Earnings</p>
                    </div>
                </div>

                <div className='my-actvy-content'>
                    <div>
                        <img src={img4} alt="" />
                    </div>
                    <div>
                        <h4>$2,000</h4>
                        <p>Withdrawals</p>
                    </div>
                </div>
        </div>
    </div>
    </div>
  )
}

export default MyActivity
